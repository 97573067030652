import pimg1 from '../images/project/img-1.jpg';
import pimg2 from '../images/project/img-2.jpg';
import pimg3 from '../images/project/img-3.jpg';
import pimg4 from '../images/project/img-31.jpg';
import pimg5 from '../images/project/img-4.jpg';
import pimg6 from '../images/project/img-5.jpg';
import pimg7 from '../images/project/img-6.jpg';
import pimg8 from '../images/project/img-7.jpg';
import pimg9 from '../images/project/img-8.jpg';
import pimg10 from '../images/project/1.png';
import pimg11 from '../images/project/2.png';
import pimg12 from '../images/project/3.png';
import pimg13 from '../images/project/4.png';
import lucky from '../images/project/Lucky1.webp';
// im wisefin from '../images/project/Wisefin1.webp';
import tasc from '../images/project/TASC_full.webp';
import murrindindi from '../images/project/murrindindiretreat.webp';
// import unibay from '../images/project/Unibay1.webp';
import luckymobile from '../images/project/luckymobile.webp';
import tascs from '../images/project/Tasc.webp';
import maruthampulam from '../images/project/pathirakali_full.webp';
// import wisefin from '../images/project/Wisefin.webp';
import wisefin from '../images/project/wisefin_full.webp';
import lminternational from '../images/project/lminternational.webp';
import vcc from '../images/project/vcc_full.webp';
import ebpo from '../images/project/E-bpo_full.webp';
import issv from '../images/project/issv_full.webp';
// import retreat from '../images/project/retreat.png';
import quest from '../images/project/quest_frankston.webp';
import unibay from '../images/project/unibay_full.webp';
import siddiveram from '../images/project/siddiveram.webp';
import integrated from '../images/project/integrated.webp';
import gilbert from '../images/project/gilbertvincent_full.webp';
import vallipuram from '../images/project/vallipuram_full.webp';
import nets from '../images/project/NETS_full.webp';
import ulIT from '../images/project/UL IT Solutions_Matrix Mantra.png';
import itwoi from '../images/project/itwoi.webp';
import mvcc from '../images/project/mvcc_full.webp';
import redex from '../images/project/redex_whole.webp';
import zineflix from '../images/project/zineflix_full.webp';
import vendormart from '../images/project/vendormart_full.jpg';
import saras from '../images/project/saras-services_full.webp';
import sannithi from '../images/project/sannithi_full.webp';
import retreat from '../images/project/retreat.webp';
import redexmobile from '../images/project/redexmobile.jpg';
import betterhalfmobile from '../images/project/betterhalf_mobile.png';
import betterhalf from '../images/project/betterhalf_full.webp';
import cinebooking from '../images/project/cinebooking_full.webp';


import ps1img1 from '../images/project-single/1.jpg';
import ps1img2 from '../images/project-single/2.jpg';
import ps1img3 from '../images/project-single/3.jpg';
import ps1img4 from '../images/project-single/4.jpg';
import ps1img5 from '../images/project-single/1.jpg';
import ps1img6 from '../images/project-single/2.jpg';
import ps1img7 from '../images/project-single/3.jpg';
import ps1img8 from '../images/project-single/5.jpg';
import ps1img9 from '../images/project-single/6.jpg';

import psub1img1 from '../images/project-single/p1.jpg';
import psub1img2 from '../images/project-single/p2.jpg';

const Projects = [
    {
        Id: '1',
        slug: 'zineflix',
        pImg: zineflix,
        ps1img: ps1img1,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'ZINEFLIX - GERMANY',
        subTitle: 'Web Design & Development',
        purl: 'https://zineflix.com/',
        date:'15-06-2024',
        country:'Germany',
        metaDescription:"Zineflix: Your go-to platform for booking movies in Germany, featuring trailers, reviews, secure payments, and personalized recommendations.",
        description:'Zineflix offers a seamless experience for film enthusiasts to stay updated with the latest and upcoming movies. Users can easily browse through a vast selection of current and forthcoming releases, read detailed synopses, and watch trailers. The intuitive search function allows users to find their favorite movies quickly.Booking a movie on Zineflix is straightforward: users can select their preferred showtime, choose their seats, and make secure payments for tickets. After payment, an e-ticket is generated and an OTP is sent to ensure the security and authenticity of the transaction. This OTP must be presented at the theater to receive the booked tickets.Zineflix also includes features such as user reviews and ratings, personalized recommendations based on viewing history, and exclusive offers and discounts. Whether you’re a casual moviegoer or a cinephile, Zineflix is designed to enhance your movie-watching experience.The innovative design and robust functionality crafted by Matrix Mantra make Zineflix the ultimate destination for all your movie booking needs.',
    },

    {
        Id: '2',
        slug: 'ul-it-solutions',
        pImg: ulIT,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'UL IT Solutions',
        subTitle: 'Web Design',
        purl: 'https://ulitsolutions.de/',
        country:'Germany',
        date:'30-10-2023',
        metaDescription: "UL IT Solutions: Experts in creating digital solutions, from websites to mobile apps, ensuring businesses thrive in the digital world.",
        description:'UL IT Solutions began because we love using technology in smart and creative ways. From day one, our goal has been to create digital solutions that make businesses successful in today’s fast-paced digital world. Whether it’s creating dynamic websites, building innovative mobile apps, providing reliable IT support, or shaping powerful marketing strategies – we’re your dedicated partners in technology. Our website was designed and developed by Matrix Mantra, ensuring a user-friendly and visually appealing experience. At UL IT Solutions, we pride ourselves on delivering top-notch services that meet the unique needs of each client, helping them thrive in the digital landscape. Join us as we leverage technology to drive your business forward.'
    },

    {
        Id: '3',
        slug: 'nets-digital-solutions',
        pImg: nets,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'NETS Digital Solutions',
        subTitle: 'Web Design',
        purl: 'https://netsdigitalsolutions.uk/',
        country:'United Kingdom',
        date:'20-10-2023',
        metaDescription: "NETS Digital Solutions: Empowering businesses with visually stunning, user-friendly web designs to build strong online brand presence.",
        description:'NETS Digital Solutions aims to empower businesses by creating visually stunning and user-friendly web designs. Our goal is to help our clients achieve online success and build a strong brand presence through innovative digital experiences. We believe that great design can make a significant impact on a business\’s growth and reputation. Our vision is to become a leading force in the web design industry. We strive to continually push creative boundaries and set new standards for excellence. We are dedicated to crafting websites that are not only functional but also impactful and memorable. At NETS Digital Solutions, we understand the importance of a well-designed website in today’s digital world. That’s why we focus on delivering top-quality designs that meet the unique needs of each client. The NETS Digital Solutions website is proudly designed by Matrix Mantra, ensuring a seamless and visually appealing online experience for our users.'
    },

    {
        Id: '4',
        slug: 'saras-services',
        pImg: saras,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'Saras Services',
        subTitle: 'Web Design',
        purl: 'https://saras-services.com/en/saraenglsih/',
        country:'Germany',
        date:'13-08-2023',
        metaDescription: "Saras Services: Expert assistance for Tamils in Germany, offering translations, legal aid, language classes, and more for over a decade.",
        description:'Welcome to Saras Services, your dedicated partner in improving the lives of Tamils in Germany. For over a decade, we have provided expert assistance and advice to the Tamil community, enhancing their quality of life. Our services have now expanded under the name Saras Services to help even more people. Our vision is to create a space where you can communicate in Tamil and improve your life in Germany. We solve your legal and bureaucratic issues efficiently and effectively. With decades of experience, we offer affordable services from translation to investment advice. Our customer support is always ready to help you achieve your goals. We provide government-approved translations, German language classes, help with obtaining a driving license, assistance with permanent residence tests, and smooth marriage registration for non-citizens. We also offer bank loans, insurance, investment schemes, and government assistance. Our website is proudly designed and developed by Matrix Mantra.'
    },

    {
        Id: '5',
        slug: 'redex',
        pImg: redex,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'REDEX',
        subTitle: 'Web Design',
        purl: 'https://redex.lk/',
        country:'Saudi Arabia',
        date:'20-03-2024',
        metaDescription: "RedEx: Transforming logistics with digital solutions, fleet services, cross-border shipping, warehouse management, and team outsourcing.",
        description:'RedEx is a multi-model, end-to-end digital platform that transforms the logistics industry by providing deep operational insights. Our services include top-tier third-party logistics, allowing you to book fleet and driver services with a minimum three-month commitment (terms and conditions apply). For cross-border shipping, RedEx offers comprehensive GCC transportation solutions with various shipping methods to suit your needs. Our warehouse solutions make it easy to store, manage, and distribute your inventory from a single vendor. RedEx\'s global team manages the entire procurement process, ensuring product quality and timely delivery according to your terms. Our audit and analytics services provide valuable insights into supplier performance, wastage, and variance analysis. Additionally, RedEx offers team outsourcing services with complete turnkey project management, acting as an extension or stand-in for your existing operations team. The RedEx website, designed by Matrix Mantra, ensures a seamless and visually appealing online experience.'
        
    },

    {
        Id: '6',
        slug: 'vendormart',
        pImg: vendormart,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'VENDORMART',
        subTitle: 'Web Design & Development',
        purl: 'https://vendormart.lk/',
        country:'SRI LANKA',
        date:'24-07-2024',
        metaDescription: "Vendor Mart: A digital platform for wholesale vendors, small vendors, and customers to manage and purchase products in bulk.",
        description:'Vendor Mart is a multi-vendor digital platform designed for wholesale vendors, small vendors, and customers. Wholesale vendors can log in to the site to add, edit, and delete their products, all under the terms and conditions set by the platform. This allows them to efficiently manage their inventory and reach a wider audience. Small vendors and customers can also log in to the site to browse through the available products. Products are organized by categories, making it easy to find what they are looking for. Small vendors have the added ability to refine their search by selecting their province, district, and city. This feature helps them locate wholesale vendors in their preferred area, making it convenient to order products in bulk from the vendors they prefer. However, customers cannot order small quantities like one or two products; the platform is designed for larger wholesale transactions. This is particularly useful for customers for events like birthday parties and weddings, where bulk purchases are necessary. Vendor Mart aims to streamline the buying and selling process for all users. It provides a user-friendly interface for wholesale vendors to manage their products and for small vendors to find and order products efficiently. Customers can also browse products, although they are restricted to bulk purchases, ensuring the platform remains focused on wholesale transactions. The innovative design and development of the website are expertly crafted by Matrix Mantra.'
        
    },

    {
        Id: '7',
        slug: 'wisefin',
        pImg: wisefin,
        ps1img: ps1img2,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'WISEFIN - AUSTRALIA',
        subTitle: 'Web Design & SEO',
        purl: 'https://wisefin.com.au/',
        metaDescription: "Wise Financial Advisory: Expert financial management in Australia, including wealth creation, insurance, SMSF, and retirement planning.",
        description:'Wise Financial Advisory is dedicated to helping you live a better life by making smart financial decisions. With over 30 years of experience, we provide top financial management services, focusing on quality and personalized advice. Our services include wealth creation and management, insurance advice, self-managed super funds (SMSF), property investment advice, retirement planning, and superannuation. We are trusted advisors, committed to your long-term financial success, ensuring value for money and appropriate advice without bias. Our team is known for its expertise, client commitment, and high-level knowledge. With over 200 satisfied clients, we continue to provide the best service and guidance. The website is beautifully designed by Matrix Mantra.',
        country:'Australia',
        date:'18-01-2023'
    },

    {
        Id: '8',
        slug: 'selvasannithi',
        pImg: sannithi,
        ps1img: ps1img2,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'SELVA SANNITHI - SRILANKA',
        subTitle: 'Web Design',
        purl: 'https://selvasannithi.com/',
        metaDescription: "Selva Sannithi Murugan Temple in Jaffna: Discover history, poojas, events, and support the temple with donations for cultural life.",
        description:'Selva Sannithi Murugan Temple, located in Jaffna, is a special Hindu temple with a long history and many miracles. Devotees love this sacred place. The temple\'s website, beautifully designed by Matrix Mantra, shares its history, past and upcoming poojas, and events. It encourages devotees to help support the temple\'s spiritual and cultural life by making donations. These contributions help keep traditions alive, maintain the temple, and support community events. Your kindness strengthens our shared commitment to faith and community. Join us in building a temple that brings our community together, promoting peace and wisdom. Show compassion by giving alms, positively helping those in need. Experience spiritual peace by booking a Pooja and start a journey of deep connection, inner calm, and blessings.',
        country:'SriLanka',
        date:'27-05-2023'

    },

    {
        Id: '9',
        slug: 'E-BPO-SriLanka',
        pImg: ebpo,
        ps1img: ps1img8,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'E-BPO  - SRILANKA',
        subTitle: 'Web Design & Seo',
        purl: 'https://e-bpo.net/',
        country:'Sri Lanka',
        date:'30-05-2023',
        metaDescription: "E-BPO: Sri Lankan BPO company specializing in accounting services for firms in Australia, UK, USA, and Canada.",
        description:'E-BPO is a highly-skilled Business Process Outsourcing (BPO) company based in Sri Lanka, with over 10 years of experience. Specializing in accounting services, we serve CPA and Management Accounting firms in Australia, the UK, the USA, and Canada. Our dedicated team helps businesses streamline processes, reduce costs, and increase efficiency. We offer various services, including outsourced bookkeeping, tax preparation and planning, financial analysis and forecasting, financial reporting, audit assistance, and payroll outsourcing. Our certified public accountants (CPAs) ensure the highest quality of work, using advanced technology and maintaining strict data security. We pride ourselves on being a trusted partner, delivering cost-effective solutions with professionalism and integrity. With E-BPO, you can save time and money, ensuring timely delivery and excellent customer support. Matrix Mantra beautifully designed the website showcasing our services.'
    },

    {
        Id: '10',
        slug: 'tasc',
        pImg: tasc,
        ps1img: ps1img3,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'TASC - AUSTRALIA',
        subTitle: 'SEO',
        purl: 'https://tascs.com.au/',
        country:'Australia',
        date:'16-02-2023',
        metaDescription: "TASC Accountants in Melbourne: Business advisory, tax returns, financial advice, and more. Expert services by Matrix Mantra.",
        description:'Maximizing your success and minimizing your tax risk, TASC Accountants in Melbourne provides prudent and proactive business advisory services. Our accountants understand how to run businesses and help you make better financial decisions at every stage. We offer a range of services, from tax returns and financial advice to business mentoring. Whether it\'s simple data entry or complex tax matters, we are dedicated to finding solutions for individuals and businesses alike. The website and SEO, expertly done by Matrix Mantra, ensure easy access to our services. With three simple steps, you can connect with our trusted tax and financial advisors: contact us, get personalized advice, and grow your business and wealth. Our services include tax solutions, self-managed super funds, business advisory, property investment advice, family business solutions, bookkeeping and payroll, and guidance for new businesses. We integrate human expertise with the latest technology to provide innovative solutions.'
    },

    {
        Id: '11',
        slug: 'maruthampulam',
        pImg: maruthampulam,
        ps1img: ps1img6,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'MARUTHAMPULAM - SRILANKA',
        subTitle: 'Web Design',
        purl: 'https://pathirakali.org/',
        country:'Sri Lanka',
        date:'13-04-2023',
        metaDescription: "Maruthampulam Pathirakali Amman Temple: Hindu temple in Jaffna, providing event details and donation options for its upkeep.",
        description:'Maruthampulam Pathirakali Amman Temple is a Hindu temple located in Polikandy, Jaffna. It is a well-known Amman Kovil, attracting devotees from the local area, across Sri Lanka, and even those who have migrated abroad. People come together for the annual chariot festival, making it a significant event. The temple\'s website, designed to keep devotees informed, provides details about regular and special poojas, as well as various events. Additionally, the website serves as a platform to collect donations for the temple\'s development and maintenance. These contributions help preserve the temple\'s sacred traditions and ensure its continued care. By supporting the temple, devotees can help maintain its spiritual and cultural vibrancy, ensuring it continues to be a place of worship and community gathering for generations to come.'
    },

    {
        Id: '12',
        slug: 'murrindindi',
        pImg: retreat,
        ps1img: ps1img6,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'MURRINDINDI - AUSTRALIA',
        subTitle: 'SEO',
        purl: 'https://murrindindiretreat.com.au/',
        country:'Australia',
        date:'10-02-2023',
        metaDescription: "Murrindindi Executive Retreat: Luxurious getaway near Melbourne with stunning views, modern amenities, and easy booking.",
        description:'Murrindindi Executive Retreat is a luxurious getaway just an hour and a half from Melbourne CBD, perfect for busy executives, couples, or business partners looking to relax and recharge. The retreat offers stunning views, spacious bedrooms, a covered BBQ area, and a large outdoor spa. Set on 105 acres, it’s close to world-class wineries and snow fields like Mt Buffalo and Mary’s Field. The retreat features modern amenities, including air conditioning, a fireplace, WiFi, a full kitchen, TV, and parking. Guests can enjoy a pool table, DVD player, stereo equipment, and a range of games. Rates range from $725 to $1,200 per night, with lower rates for longer stays. The website, optimized by Matrix Mantra, ensures easy access to information and booking options.'
    },

    {
        Id: '13',
        slug: 'itwoi-warranty',
        pImg: itwoi,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'itwoi international',
        subTitle: 'Web Development',
        purl: 'https://itwoiinternational.lk/',
        country:'Sri Lanka',
        date:'05-11-2023',
        metaDescription: "iTWOi International: Efficient mobile phone warranty management system with features for inventory and repair tracking.",
        description:'iTWOi International is a warranty management system that efficiently handles mobile phone warranties. Designed, developed, and optimized by Matrix Mantra, this web application is very user-friendly. The system has many useful features, including inventory management, which helps users keep track of all their mobile phones. It also includes warranty tracking, allowing users to easily see which devices are still under warranty and for how long. Additionally, the system manages repairs, making the process simple from start to finish. Users can log repair requests, track the status of repairs, and ensure that all necessary steps are followed. By including all these features into one platform, iTWOi International makes it easy for users to handle every aspect of mobile phone warranties. This system is valuable for both consumers and businesses, as it saves time and reduces the hassle of managing warranties and repairs separately. With iTWOi International, users can streamline their warranty and repair processes, ensuring that everything is organized and easily accessible. This comprehensive approach makes it an essential tool for anyone looking to manage mobile phone warranties efficiently.'
    },

    {
        Id: '14',
        slug: 'quest-frankston',
        pImg: quest,
        ps1img: ps1img7,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'QUEST FRANKSTON - AUSTRALIA ',
        subTitle: 'Social Media Marketing ',
        country:'Australia',
        date:'02-07-2023',
        purl: 'https://www.questapartments.com.au/',
        metaDescription: "Quest Apartment Hotels: Leading apartment hotel brand in Australasia, offering top accommodation and strong local franchise ownership.",
        description:'Quest Apartment Hotels, Australasia\'s leading apartment hotel brand, is a member of The Ascott Limited, the world\'s largest serviced apartment network, operating locally under Ascott Australia. With over 160 locations in Australia, New Zealand, the UK, and Fiji, Quest has served modern business travelers for 35 years. Each hotel operates under a franchise model, run by dedicated local owners committed to their business\'s success. Quest has earned numerous awards for excellence in accommodation, franchising, and business. The brand\'s core values include mutual respect, long-term relationships, understanding, alignment, and striving for personal bests. The social media marketing for Quest Apartment Hotels is expertly managed by Matrix Mantra, enhancing the brand\'s reach and engagement with its audience. Quest continues to grow and innovate, providing top-notch services to its guests and maintaining strong relationships with stakeholders.'
    },

    {
        Id: '15',
        slug: 'mvcc',
        pImg: mvcc,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'Meesalai Veerasingam Central College',
        subTitle: 'Web Design',
        purl: 'https://mvcc.lk/',
        country:'Sri Lanka',
        date:'20-12-2023',
        metaDescription: "MVCC: Showcasing history, events, academic achievements, and supporting school development through donations. Designed by Matrix Mantra.",
        description:'Meesalai Veerasingam Central College\'s website, designed by Matrix Mantra, provides a detailed overview of the school\'s history, past and upcoming events, and academic achievements. It highlights the school\'s excellent Ordinary and Advanced Level results, showcasing their success in preparing students for university. The website also features information about the principal and teachers, including their qualifications and grades. Additionally, it serves as a platform for old students and supporters to make donations, helping to support the school\'s ongoing development and success. This user-friendly site ensures easy access to all essential information, celebrating the school\'s achievements and promoting community support.'
        
    },

    {
        Id: '16',
        slug: 'unibay',
        pImg: unibay,
        ps1img: ps1img5,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'UNIBAY - SRILANKA',
        subTitle: 'Web Design & SEO',
        purl: 'https://unibay.lk/',
        country:'Sri Lanka',
        date:'25-03-2023',
        metaDescription: "Unibay: Your go-to store in Sri Lanka for the latest smartphones, accessories, and tech deals. Expert web design and SEO by Matrix Mantra.",
        description:'Unibay is your top store for the latest smartphones and accessories, offering popular brands and great deals for tech lovers. Our website design and SEO, expertly done by Matrix Mantra, make shopping easy and enjoyable. At Unibay, you can find a variety of products including desktops, laptops, mobile phones, and accessories. We also provide technical support and product suggestions to help you choose the best items. Featured products include the Dell Inspiron 3530 i7 13th Gen, Dell Vostro 3500 Intel Core i3, and Dell Vostro 3510 Intel Core i5. Additionally, we offer a wide range of smartphone accessories such as chargers, cases, and screen protectors to enhance your mobile experience. Enjoy fast delivery, 24/7 customer support, and reliable warranty services with Unibay. Our commitment to customer satisfaction ensures you receive the best service and products. Shop with confidence at Unibay, where quality meets affordability and innovation.'
    },

    {
        Id: '17',
        slug: 'gilbert-vincent',
        pImg: gilbert,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'Gilbert Vincent Shop',
        subTitle: 'Web Design & Development',
        purl: 'https://gilbertvincent.ch/',
        country:'Germany',
        date:'20-08-2023',
        metaDescription:"Gilbert Vincent Shop: Swiss website for easy repair bookings and parts purchasing for mobile phones, laptops, and tablets.",
        description:'Gilbert Vincent\'s repair booking website in Switzerland, expertly designed and developed by Matrix Mantra, offers a smooth experience for users needing repairs for mobile phones, laptops, tablets, and other devices. The website makes it easy to schedule repair appointments, keeping everything organized and straightforward. Customers can quickly book a repair, and the system efficiently manages the inventory, ensuring that all necessary parts are available when needed. The billing process is also simplified, making it easy for customers to understand and complete their payments. Additionally, the website provides a convenient option for customers to buy parts for various devices directly, saving them time and effort. Matrix Mantra\'s expertise ensures that the website is user-friendly, with a clean and intuitive interface. This makes it simple for customers to navigate the site, book repairs, and find the components they need. Overall, the website streamlines the entire repair process, making it hassle-free and convenient for everyone. Whether you need to schedule a repair or purchase parts, Gilbert Vincent\'s repair booking website provides a reliable and efficient solution for all your device repair needs.'
    },

    {
        Id: '18',
        slug: 'sri-vallipuram-aalwar',
        pImg: vallipuram,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'Sri Vallipuram Aalwar',
        subTitle: 'Web Design',
        purl: 'https://vallipuraalvar.lk/',
        country:'Sri Lanka',
        date:'05-09-2023',
        metaDescription: "Sri Vallipuram Aalwar: Explore the history and events of this revered Jaffna temple. Support traditions and community through donations.",
        description:'Sri Vallipura Aalvar Temple, located in Point Pedro, Jaffna, is a revered Hindu temple known for its rich history and numerous miracles. Devotees hold this sacred site in high regard. The temple\'s website, elegantly designed by Matrix Mantra, provides insights into its historical significance, past and upcoming poojas, and events. It invites devotees to support the temple\'s spiritual and cultural activities through donations. These contributions are essential for preserving traditions, maintaining the temple, and facilitating community events. Your generosity reinforces our collective dedication to faith and community. Join us in nurturing a temple that unites our community, promoting peace and wisdom. Show compassion by giving alms, thereby positively impacting those in need. Experience spiritual tranquility by booking a Pooja, embarking on a journey of profound connection, inner serenity, and blessings.'
    },

    {
        Id: '19',
        slug: 'varany-college',
        pImg: vcc,
        ps1img: ps1img7,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'VARANY COLLEGE - SRILANKA',
        subTitle: 'Web Design',
        purl: 'https://varanycentralcollege.lk/',
        country:'Sri Lanka',
        date:'20-05-2023',
        metaDescription:"Varany Central College: Explore the school's history, achievements, and upcoming events. Support its growth and success through donations.",
        description:'Varany Central College\'s website, crafted by Matrix Mantra, offers a comprehensive view of the school\'s history, notable past events, and upcoming activities. It showcases the school\'s impressive Ordinary and Advanced Level results, emphasizing its success in preparing students for university education. The site also provides detailed information about the principal and teachers, including their qualifications and grades. Furthermore, it serves as a convenient platform for alumni and supporters to make donations, aiding the school\'s continuous growth and success. This user-friendly website ensures easy access to all essential information, celebrating Varany Central College\'s achievements and fostering community support.'
    },

    {
        Id: '20',
        slug: 'siddiveram-temple',
        pImg: siddiveram,
        ps1img: ps1img8,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'Siddiveram Temple',
        subTitle: 'Web Design',
        purl: 'https://siddiveram.com/',
        country:'Sri Lanka',
        date:'19-07-2023',
        metaDescription:"Siddiveram Temple: Explore the rich history and spiritual events of this Jaffna temple. Support traditions and community through donations.",
        description:'Siddiveram Kannagi Amman Temple, located in Jaffna, is a respected Hindu temple known for its rich history and many miracles. Devotees hold this sacred place in high regard. The temple\'s website, designed by Matrix Mantra, provides detailed information about its history, past and upcoming poojas, and events. It also encourages devotees to support the temple\'s spiritual and cultural activities through donations. These donations are important for keeping traditions alive, maintaining the temple, and supporting community events. Your generosity helps strengthen our shared commitment to faith and community. By giving alms, you can make a positive impact on those in need. Experience spiritual peace by booking a Pooja, starting a journey of deep connection, inner calm, and blessings. Join us in building a temple that unites our community and promotes peace and wisdom.'
    },

    {
        Id: '21',
        slug: 'ISSV-SriLanka',
        pImg: issv,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'ISSV - SRILANKA',
        subTitle: 'Web Design',
        purl: 'https://idaikkurichchissv.lk/',
        country:'Sri Lanka',
        date:'10-06-2023',
        metaDescription:"ISSV: Explore the school’s history, achievements, and activities. Support ongoing development through donations. Designed by Matrix Mantra.",
        description:'IDAIKKURICHCHI Sri Subramaniya Vidyalayam\’s website, designed by Matrix Mantra, offers a clear overview of the school\'s history, key events, and upcoming activities. The site highlights the school’s strong Ordinary and Advanced Level results, showcasing its effectiveness in preparing students for higher education. It includes detailed profiles of the principal and teachers, outlining their qualifications and achievements. The website also provides a convenient way for alumni and supporters to make donations, helping to support the school\'s ongoing development. With its user-friendly design, the website makes it easy to access important information, celebrate the school’s successes, and encourage community involvement.'
    },

    {
        Id: '22',
        slug: 'LM-international',
        pImg: lminternational,
        ps1img: ps1img6,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'LM_INTERNATIONAL - SRILANKA',
        subTitle: 'Web Design',
        purl: 'https://lminternational.lk/',
        country:'Sri Lanka',
        date:'26-06-2023',
        metaDescription:"LM_INTERNATIONAL: Explore the latest electronics and accessories with a user-friendly, seamless shopping experience. ",
        description:'LM_INTERNATIONAL is your top choice for the latest electronic gadgets. Designed, developed, and optimized by Matrix Mantra, this online store features a wide range of high-quality products, including mobile phones, smartwatches, cables, and more. The website boasts a modern design and an easy-to-use interface, making your shopping experience smooth and enjoyable. Whether you\'re looking for the newest tech innovations or essential accessories, LM_INTERNATIONAL has everything you need. The site is built to be user-friendly, ensuring that you can find and purchase your desired items quickly and effortlessly. With its commitment to offering top-notch electronics and a hassle-free shopping experience, LM_INTERNATIONAL stands out as a reliable destination for all your tech needs.'
    },

    {
        Id: '23',
        slug: 'lucky-mobile',
        pImg: luckymobile,
        ps1img: ps1img1,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'LUCKY MOBILE - SRILANKA',
        subTitle: 'Web Design',
        purl: 'https://luckymobile.lk/',
        date:'15-10-2022',
        country:'Sri Lanka',
        metaDescription:"Lucky Mobile: Shop the latest mobile phones, laptops, and accessories with a user-friendly design for a seamless tech shopping experience. ",
        description:'Welcome to Lucky Mobile, designed by Matrix Mantra to offer an exceptional shopping experience for all your tech needs. We have created a platform with a wide range of products, including mobile phones, laptops, tablets, and accessories. Our focus on quality is evident in the user-friendly design of the website, which ensures a smooth and enjoyable shopping experience for tech enthusiasts. Whether you\'re searching for the latest smartphones, powerful laptops, or versatile tablets, Lucky Mobile has you covered. The website is built to make finding and purchasing your desired tech products easy and efficient. Explore our selection today and enjoy a seamless shopping journey tailored just for you.'
    },   
   
    {
        Id: '24',
        slug: 'integrated-corporate-advisory',
        pImg: integrated,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'Integrated Corporate Advisory',
        subTitle: 'Social Media Marketing',
        country:'Sri Lanka',
        date:'05-08-2023',
        metaDescription:"Integrated Corporate Advisory: Sri Lankan firm offering bookkeeping, accounting, and investment advice with enhanced online reach. ",
        description:'Integrated Corporate Advisory, based in Sri Lanka, blends local expertise with global insights to provide effective business services. They offer a range of solutions, including bookkeeping and accounting, corporate services, and investment advice to help you manage and navigate risky assets. Their team is dedicated to delivering high-quality support tailored to your needs. To enhance their online presence, Matrix Mantra handles their social media marketing, ensuring that their services reach a broader audience in a dynamic and engaging way. With their combined expertise and effective online strategy, Integrated Corporate Advisory is well-equipped to support your business and investment goals.'
    },

    {
        Id: '25',
        slug: 'scl-mobile',
        pImg: redexmobile,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'SCL MOBILE APP',
        subTitle: 'Mobile App Development',
        country:'Saudi Arabia',
        date:'15-07-2024',
        metaDescription:"SCL Mobile App: Advanced logistics solution offering Fleet Trip, Fleet Leasing, and Fleet Rental for long-term and short-term vehicle needs. ",
        // description:'The SCL Mobile App provides a complete logistics management solution with three main services: Fleet Trip, Fleet Leasing, and Fleet Rental. The Fleet Trip service is designed for users who need to transport cargo from one place to another. Users can choose from a range of vehicles, including cars and trucks, based on their needs. They can set multiple pickup and delivery locations and choose a specific date and time for their booking. The app calculates the cost automatically, considering factors like vehicle type and distance. Users can opt for instant booking, where the system sends requests to nearby drivers until one accepts, or advance booking, which notifies service providers in the pickup area. The app keeps users informed with real-time notifications about their booking status, including driver assignments and estimated delivery times. It also tracks cargo in real-time and requires OTP confirmation when the trip is completed. For longer-term needs, the Fleet Leasing service allows users to lease vehicles for periods ranging from six months to three years. Users can select the type of vehicle they need, the duration of the lease, and the location. The app calculates the leasing cost based on these factors and handles the booking process. It also provides real-time tracking of vehicles and manages payment processing, including credit terms and advance payments. The Fleet Rental service is perfect for short-term needs, offering vehicles for periods ranging from 24 hours to a few months. This service follows a similar process to leasing but is designed for shorter durations. The app’s user-friendly interface ensures that all logistics management tasks are handled smoothly, making it easy for users to manage their transportation needs efficiently.'
        description:'The SCL Mobile App provides an Uber-style more advanced logistics management solution with three main services: Fleet Trip allows for efficient cargo transport with multiple pickup and delivery points, real-time tracking, and OTP confirmation. Fleet Leasing accommodates long-term vehicle needs, providing cost calculations, bookings, and payment management. Fleet Rental offers short-term vehicle rentals, following a similar process to leasing but for shorter durations.'
    },  
    
    {
        Id: '26',
        slug: 'betterhalf-mobile',
        pImg: betterhalfmobile,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'Betterhalf MOBILE APP',
        subTitle: 'Mobile App Development',
        country:'Sri Lanka',
        date:'14-09-2024',
        metaDescription:"Find your ideal partner with BetterHalf Mobile App. Sign up, select a package, and explore matches based on your preferences",
        description:'BetterHalf Mobile App helps users find their perfect partner. To get started, users sign up and select a subscription package that suits their needs. After subscribing, they can explore potential partners based on their personal preferences and criteria. The app features a user-friendly interface that lets users easily set their preferences, such as interests, values, and goals. With BetterHalf, users find compatible matches more simply and personally. The app creates a secure and supportive environment for users to connect and build meaningful relationships. Whether users seek a serious commitment or are just exploring their options, BetterHalf makes the journey towards finding a partner smooth and enjoyable. Sign up today and begin your journey to finding your ideal match with BetterHalf!'
    },


    {
        Id: '27',
        slug: 'betterhalf',
        pImg: betterhalf,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'Betterhalf',
        subTitle: 'Web Design and Web Development',
        purl: 'https://betterhalf.lk/',
        country:'Sri Lanka',
        date:'14-09-2024',
        metaDescription:"Discover your ideal partner with BetterHalf. Sign up, choose a package, and find matches based on your preferences.",
        description:'BetterHalf is your gateway to finding the ideal partner. To begin, users need to sign up and choose a subscription package that aligns with their needs. Once subscribed, they can search for potential partners based on their specific preferences and criteria. The app provides an intuitive interface, allowing users to easily specify their interests, values, and goals. BetterHalf streamlines the process of finding compatible matches, offering a more personalized and efficient experience. It provides a secure, supportive platform for users to connect and form meaningful relationships. Whether you’re looking for a serious commitment or simply exploring possibilities, BetterHalf ensures a smooth and enjoyable journey to finding your perfect match. Join today and start your quest for love with BetterHalf!'
    },

    {
        Id: '28',
        slug: 'cinebooking',
        pImg: cinebooking,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'Cinebooking',
        subTitle: 'Web Design and Web Development',
        purl: 'https://cinebooking.lk/',
        country:'Sri Lanka',
        date:'05-09-2024',
        metaDescription:"Book movies effortlessly with CineBooking. Browse releases, watch trailers, and enjoy secure ticketing and personalized recommendations.",
        description:'CineBooking offers a top-notch platform for film lovers to stay on top of the latest and upcoming movies. Users can easily browse a wide range of current and forthcoming releases, read detailed movie synopses, and watch trailers. The site features a user-friendly search function that makes finding your favorite films quick and simple.Booking a movie is straightforward on CineBooking: just select your desired showtime, choose your seats, and make secure payments. After payment, you\'ll receive an e-ticket along with an OTP for enhanced security. Present this OTP at the theater to pick up your tickets. CineBooking also provides user reviews, ratings, and personalized recommendations based on your viewing history. Enjoy exclusive offers and discounts tailored to enhance your movie-watching experience.  Whether you\'re a casual moviegoer or a dedicated cinephile, CineBooking, powered by Matrix Mantra’s innovative design and robust features, is your ultimate destination for seamless movie bookings.'
    },

   
];

export default Projects;
