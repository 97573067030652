// import React from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import Projects from '../../api/project';
// import { Link } from 'react-router-dom';
// import SectionTitle2 from '../SectionTitle2/SectionTitle2';
// import { makeStyles } from '@material-ui/core/styles';
// import { Paper, Tabs, Tab } from '@material-ui/core';
// import { useState, useEffect } from 'react';
// import './ProjectSection.css';

// const settings = {
//     dots: false,
//     rows: 2,
//     arrows: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     responsive: [
//         {
//             breakpoint: 1400,
//             settings: {
//                 slidesToShow: 3,
//                 slidesToScroll: 1,
//                 infinite: true,
//             },
//         },
//         {
//             breakpoint: 1024,
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1,
//                 infinite: true,
//             },
//         },
//         {
//             breakpoint: 991,
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1,
//             },
//         },
//         {
//             breakpoint: 767,
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1,
//             },
//         },
//         {
//             breakpoint: 480,
//             settings: {
//                 dots: false,
//                 rows: 1,
//                 arrows: true,
//                 speed: 500,
//                 slidesToShow: 1,
//                 slidesToScroll: 1,
//                 autoplay: true,
//             },
//         },
//     ],
// };

// const ClickHandler = () => {
//     window.scrollTo(12, 0);
// };

// const useStyles = makeStyles({
//     root: {
//         flexGrow: 1,
//     },
// });

// const ProjectSection = (props) => {
//     const classes = useStyles();
//     const [value, setValue] = React.useState(0);

//     const handleChange = (event, newValue) => {
//         setValue(newValue);
//     };

//     const [isMobile, setIsMobile] = useState(false);

//     useEffect(() => {
//         const handleResize = () => {
//             setIsMobile(window.innerWidth <= 767); // Adjust the breakpoint as needed
//         };

//         // Initial check on component mount
//         handleResize();

//         // Add event listener for window resize
//         window.addEventListener('resize', handleResize);

//         // Cleanup event listener on component unmount
//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);


//     return (
//         <section className={`wpo-project-section section-padding ${props.pClass}`}>
//             <div className="container">
//                 <SectionTitle2
//                     subTitle={'OUR PORTFOLIO'}
//                     Title={'Successful Work of Matrix Mantra'}
//                 />
//             </div>

//             {isMobile ? (
//                 // Render projects one by one for mobile view
//                 <div className="container-fluid">
//                     <div className="row">
//                         <div className="col col-xs-12 sortable-project">
//                             {Projects.map((project, prj) => (
//                                 <div className="grid" key={prj}>
//                                     <a href={`/project/${project.slug}/${project.Id}`}>
//                                         <div className="wpo-project-item">
//                                             <img
//                                                 src={project.pImg}
//                                                 alt="project"
//                                                 className="img img-responsive"
//                                             />
//                                            <div className="mobile-project-overlay">
//                                                 <h3 className="mobile-project-title">{project.title}   <br /> {project.subTitle}</h3>   
//                                             </div>

//                                         </div>
//                                     </a>
//                                 </div>
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//             ) : (
//                 // Render the Slider component for larger screens
//                 <div className="container-fluid">
//                     <div className="row">
//                         <div className="col col-xs-12 sortable-project">
//                             <div className="wpo-project-container project-slider owl-carousel">
//                                 <Slider {...settings}>
//                                     {Projects.map((project, prj) => (
//                                         <div className="grid" key={prj}>
//                                             <a href={`/project/${project.slug}/${project.Id}`}>
//                                                 <div className="wpo-project-item">
//                                                     <img
//                                                         src={project.pImg}
//                                                         alt="project"
//                                                         className="img img-responsive"
//                                                     />
//                                                     <div className="wpo-project-text">
//                                                         <h3>{project.title}</h3>
//                                                         <span>{project.subTitle}</span>
//                                                     </div>
//                                                 </div>
//                                             </a>
//                                         </div>
//                                     ))}
//                                 </Slider>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </section>
//     );
// };

// export default ProjectSection;

// import React from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import Projects from '../../api/project';
// import { Link } from 'react-router-dom';
// import SectionTitle2 from '../SectionTitle2/SectionTitle2';
// import { makeStyles } from '@material-ui/core/styles';
// import { Paper, Tabs, Tab } from '@material-ui/core';
// import { useState, useEffect } from 'react';
// import './ProjectSection.css';

// const settings = {
//     dots: false,
//     rows: 2,
//     arrows: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     responsive: [
//         {
//             breakpoint: 1400,
//             settings: {
//                 slidesToShow: 3,
//                 slidesToScroll: 1,
//                 infinite: true,
//             },
//         },
//         {
//             breakpoint: 1024,
//             settings: {
//                 slidesToShow: 3,
//                 slidesToScroll: 1,
//                 infinite: true,
//             },
//         },
//         {
//             breakpoint: 991,
//             settings: {
//                 slidesToShow: 3,
//                 slidesToScroll: 1,
//             },
//         },
//         {
//             breakpoint: 767,
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1,
//             },
//         },
//         {
//             breakpoint: 480,
//             settings: {
//                 dots: false,
//                 rows: 1,
//                 arrows: true,
//                 speed: 500,
//                 slidesToShow: 1,
//                 slidesToScroll: 1,
//                 autoplay: true,
//             },
//         },
//     ],
// };

// const ClickHandler = () => {
//     window.scrollTo(12, 0);
// };

// const useStyles = makeStyles({
//     root: {
//         flexGrow: 1,
//     },
// });



// const ProjectSection = (props) => {
//     const classes = useStyles();
//     const [value, setValue] = React.useState(0);

//     const handleChange = (event, newValue) => {
//         setValue(newValue);
//     };

//     const [isMobile, setIsMobile] = useState(false);

//     useEffect(() => {
//         const handleResize = () => {
//             setIsMobile(window.innerWidth <= 767); // Adjust the breakpoint as needed
//         };

//         // Initial check on component mount
//         handleResize();

//         // Add event listener for window resize
//         window.addEventListener('resize', handleResize);

//         // Cleanup event listener on component unmount
//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);

  
//     const ProjectsComponent = ({ Projects, isMobile }) => {
//       const [visibleProjects, setVisibleProjects] = useState(10);
  
//       const loadMoreProjects = () => {
//         setVisibleProjects((prevVisibleProjects) => prevVisibleProjects + 10);
//       };
  
//       return (
//         <section className={`wpo-project-section section-padding ${props.pClass}`}>
//           <div className="container">
//             <SectionTitle2 subTitle={'OUR PORTFOLIO'} Title={'Successful Work of Matrix Mantra'} />
//           </div>
  
//           {isMobile ? (
//             // Render projects one by one for mobile view
//             <div className="container-fluid">
//               <div className="row">
//                 <div className="col col-xs-12 sortable-project">
               
//                     {Projects.slice(0, visibleProjects).map((project, prj) => (
//                       <div className="grid" key={prj}>
//                         <a href={`/project/${project.slug}/${project.Id}`}>
//                           <div className="wpo-project-item">
//                             <img
//                               src={project.pImg}
//                               alt="project"
//                               className="img img-responsive"
//                             />
//                                <div className="mobile-project-overlay">
//                                     <h3 className="mobile-project-title">{project.title}   <br /> {project.subTitle}</h3>   
//                                 </div>

//                           </div>
//                         </a>
//                       </div>
//                     ))}
//                   </div>
//                   {visibleProjects < Projects.length && (
//                     <div className="load-more-button">
//                       <button onClick={loadMoreProjects}>Load More</button>
//                     </div>
//                   )}
                
//               </div>
//             </div>
//           ) : (
//             // Render the Slider component for larger screens
//             <div className="container-fluid">
//               <div className="row">
//                 <div className="col col-xs-12 sortable-project">
//                   <div className="wpo-project-container project-slider owl-carousel">
//                     {Projects.map((project, prj) => (
//                       <div className="grid" key={prj}>
//                         <a href={`/project/${project.slug}/${project.Id}`}>
//                           <div className="wpo-project-item">
//                             <img
//                               src={project.pImg}
//                               alt="project"
//                               className="img img-responsive"
//                             />
//                             <div className="wpo-project-text">
//                               <h3>{project.title}</h3>
//                               <span>{project.subTitle}</span>
//                             </div>
//                           </div>
//                         </a>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           )}
//         </section>
//       );
//     };
  
//     return <ProjectsComponent Projects={Projects} isMobile={isMobile} />;
//   };
  
//   export default ProjectSection;


// ... (existing imports)
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Projects from '../../api/project';
import { Link } from 'react-router-dom';
import SectionTitle2 from '../SectionTitle2/SectionTitle2';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { useState, useEffect } from 'react';
import './ProjectSection.css';

const settings = {
    dots: false,
    rows: 2,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                dots: false,
                rows: 1,
                arrows: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
            },
        },
    ],
};

const ClickHandler = () => {
    window.scrollTo(12, 0);
};

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

const sortedProjects = Projects.sort((a, b) => a.order - b.order);

const ProjectSection = (props) => {
    // ... (existing code)
    const classes = useStyles();
        const [value, setValue] = React.useState(0);
    
        const handleChange = (event, newValue) => {
            setValue(newValue);
        };
    
        const [isMobile, setIsMobile] = useState(false);
        const [filterSubtitle, setFilterSubtitle] = useState(''); // State for filter
    
        useEffect(() => {
            const handleResize = () => {
                setIsMobile(window.innerWidth <= 767); // Adjust the breakpoint as needed
            };
    
            // Initial check on component mount
            handleResize();
    
            // Add event listener for window resize
            window.addEventListener('resize', handleResize);
    
            // Cleanup event listener on component unmount
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);

    //      // Filter projects based on subtitle
    // const filteredProjects = filterSubtitle
    // ? Projects.filter((project) => project.subTitle === filterSubtitle)
    // : Projects;

    // Filter projects based on subtitle
    // const filteredProjects = filterSubtitle
    //     ? Projects.filter((project) =>
    //         project.subTitle.split(' & ').includes(filterSubtitle)
    //     )
    //     : Projects;

      // Filter projects based on subtitle
      const filteredProjects = filterSubtitle
      ? Projects.filter((project) =>
          project.subTitle.includes(filterSubtitle)
      )
      : Projects;

// Button click handlers
const handleFilterClick = (subtitle) => {
    setFilterSubtitle(subtitle);
};

  // Determine which button is selected
  const getButtonClass = (subtitle) => {
    return filterSubtitle === subtitle ? 'selected' : '';
};
  
    // const ProjectsComponent = ({ Projects, isMobile }) => {
    //   const [visibleProjects, setVisibleProjects] = useState(12);
  
    //   const loadMoreProjects = () => {
    //     setVisibleProjects((prevVisibleProjects) => prevVisibleProjects + 10);
    //   };
  
      return (
        <Fragment>
            <Helmet>
                <title>Projects</title>
                <meta
                    name="description"
                    content="Contact us to inquire about any of our services or business proposals."
                />
                <link rel="canonical" href="https://matrixmantra.lk/projects" />
            </Helmet>
            <section className={`wpo-project-section section-padding ${props.pClass}`}>
              <div className="container">
                <SectionTitle2 subTitle={'OUR PORTFOLIO'} Title={'Successful Work of Matrix Mantra'} />
              </div>

                  <div className="filter-buttons">
                    <button
                        className={getButtonClass('')}
                        onClick={() => handleFilterClick('')}
                    >
                         All
                    </button>

                    <button
                        className={getButtonClass('Web Design')}
                        onClick={() => handleFilterClick('Web Design')}
                    >
                        Web Design
                    </button>
                    <button
                        className={getButtonClass('Web Development')}
                        onClick={() => handleFilterClick('Web Development')}
                    >
                        Web Development
                    </button>
                    <button
                        className={getButtonClass('SEO')}
                        onClick={() => handleFilterClick('SEO')}
                    >
                        SEO
                    </button>
                    <button
                        className={getButtonClass('Mobile App')}
                        onClick={() => handleFilterClick('Mobile App')}
                    >
                        Mobile App
                    </button>

                    <button
                      className={getButtonClass('Social Media Marketing')}
                      onClick={() => handleFilterClick('Social Media Marketing')}
                    >
                      Digital Marketing
                    </button>
                    
                  </div>

      
              <div className="container-fluid">
                <div className="row">
                  <div className="col col-xs-12 sortable-project">
                    {isMobile ? (
                      <div className="">
                      {/* Render projects one by one for mobile view */}
                      <React.Fragment>
                        {/* {Projects.slice(0, visibleProjects).map((project, prj) => ( */}
                        {filteredProjects.map((project, prj) => (
                          <div className="grid" key={prj}>
                            <a href={`/project/${project.slug}/${project.Id}`}>
                              <div className="wpo-project-item">
                                <img
                                  src={project.pImg}
                                  alt="project"
                                  className="img img-responsive"
                                />
                                <div className="mobile-project-overlay ">
                                  <h3 className="mobile-project-title">
                                    {project.title} <br /> {project.subTitle}
                                  </h3>
                                </div>
                              </div>
                            </a>
                          </div>
                        ))}
                      </React.Fragment>
                      </div>
                    ) : (
                      // Render the Slider component for larger screens
                      <div className="wpo-project-container my-container project-slider owl-carousel">
                        {/* {Projects.slice(0, visibleProjects).map((project, prj) => ( */}
                        {filteredProjects.map((project, prj) => (
                        <div className="grid my-grid" key={prj}>
                          <a href={`/project/${project.slug}/${project.Id}`}>
                            <div className="wpo-project-item">
                              <img
                                src={project.pImg}
                                alt="project"
                                className="img img-responsive"
                              />
                                <div className="wpo-project-text">
                                  <h3>{project.title}</h3>
                                  <span>{project.subTitle}</span>
                                </div>
                              </div>
                            </a>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
      
              {/* {visibleProjects < Projects.length && (
              <div className="button-container">
              <button className="load-more-button" onClick={loadMoreProjects}>Load More</button>
            </div> */}
    {/*          
                <button className="load-more-button" onClick={loadMoreProjects}>Load More</button>
              )} */}
            </section>
        </Fragment>
      );
    };
  
    // return <ProjectsComponent Projects={Projects} isMobile={isMobile} />;
  // };
  
  export default ProjectSection;
  
  