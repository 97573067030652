import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import About from '../../components/about/about';
import BlogSection from '../../components/BlogSection/BlogSection';
import CtaSection from '../../components/ctaSection/ctaSection';
import Footer2 from '../../components/footer2/Footer2';
import FunFact from '../../components/FunFact/FunFact';
import Hero from '../../components/hero/hero';
import Navbar3 from '../../components/Navbar3/Navbar3';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import ProjectSection3 from '../../components/ProjectSection3/ProjectSection3';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import TeamSection from '../../components/TeamSection/TeamSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import abimg from '../../images/about.webp';
import Footer from '../../components/footer/Footer';

const HomePage = () => {
    return (
        <Fragment>
            <Helmet>
                <title>
                    Web Design in Jaffna
                </title>
                <meta
                    name="description"
                    content="Start your digital journey with web design in Jaffna, where creativity meets technology. Improve your online presence with custom solutions for success and innovation."
                />
            </Helmet>
            <Navbar3 topbarNone={'topbar-none'} />
            <Hero 
            heroslogan="Web design company in Jaffna"
            herotitle="Websites created to enhance your brand presence." 
            herocontent="We specialize in crafting exceptional websites, ensuring stunning designs and seamless functionality to elevate your online presence."/>
            <About 
            aboutTitle="Welcome To Matrix Mantra"
            abimg={abimg} 
            service="Web Design" 
            place="Jaffna"
            aboutcontent="Explore our creative hub, where we turn ideas into outstanding web design experiences, focusing on aesthetics and functionality. Join us to bring your concepts to life for a successful online presence. Our rapid growth in Jaffna and beyond reflects the satisfaction of our clients, who appreciate our commitment to excellence and successful project outcomes. We strive to deliver compelling digital solutions tailored to your needs, ensuring your brand stands out in the competitive online landscape."/>
            <ServiceSection />
            <CtaSection tagline="web design company in Jaffna."/>
            {/* <FunFact/> */}
            {/* <TeamSection/> */}
            <ProjectSection3 />
            <Testimonial />
            <BlogSection landingPage="web-design" />
            {/* <PartnerSection /> */}
            <ContactInfo />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    );
};
export default HomePage;
