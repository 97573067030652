import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import SectionTitle from '../SectionTitle/SectionTitle';
import Services from '../../api/service';
import smg1 from '../../images/service/shape-2.png';
import smg2 from '../../images/service/shape-3.png';

const ClickHandler = () => {
    window.scrollTo(10, 0);
};

const ServiceSectionS3 = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Services</title>
                <meta
                    name="description"
                    content="Contact us to inquire about any of our services or business proposals."
                />
                  <link rel="canonical" href="https://matrixmantra.lk/services" />
            </Helmet>
            <section className="wpo-service-section-s4 section-padding">
                <div className="container">
                    <SectionTitle subTitle={'Our Services'} Title={'What We Do ?'} />
                    <div className="row">
                        {Services.map((service, srv) => (
                            <div className="col-lg-6 col-md-12 col-12" key={srv}>
                                <a href={`/service/${service.slug}/${service.Id}`}>
                                    <div className="wpo-service-item">
                                        <div className="wpo-service-text">
                                            <div className="service-icon">
                                                <i
                                                    className={`fi ${service.icon}`}
                                                ></i>
                                            </div>
                                            <div className="service-content">
                                                <h2>{service.sTitle}</h2>
                                                <p>{service.description}</p>
                                                <Link
                                                    onClick={ClickHandler}
                                                    to={`/service/${service.slug}/${service.Id}`}
                                                >
                                                    Details
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="shape-1">
                                            <img src={smg1} alt="" loading='lazy'/>
                                        </div>
                                        <div className="shape-2">
                                            <img src={smg2} alt="" loading='lazy'/>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default ServiceSectionS3;
