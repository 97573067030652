// import React, { Fragment } from 'react';
// import { Helmet } from 'react-helmet';
// import Navbar3 from '../../components/Navbar3/Navbar3';
// import PageTitle from '../../components/pagetitle/PageTitle';
// import Scrollbar from '../../components/scrollbar/scrollbar';
// import { Link, useParams } from 'react-router-dom';
// import Services from '../../api/service';
// import ServiceSidebar from './sidebar';
// import Contact from './contact';
// import Footer2 from '../../components/footer2/Footer2';
// import WebdesignFaq from './WebdesignFaq';
// import WebdevelopmentFaq from './WebdevelopmentFaq';
// import SocialMediaMarketingFaq from './SocialMediaMarketingFaq';
// import SoftwareDevelopmentFaq from './SoftwareDevelopmentFaq';
// import SeoFaq from './SeoFaq';
// import DigitalMarketingFaq from './DigitalMarketingFaq';


// const ServiceSinglePage = (props) => {
//     const { id } = useParams();

//     const serviceDetails = Services.find((item) => item.Id === id);

//     const ClickHandler = () => {
//         window.scrollTo(10, 0);
//     };

//     return (
//         <Fragment>
//             <Helmet>
//                 <title>{`${serviceDetails.sTitle} | Matrix Mantra`}</title>
//                 <meta name="description" content={serviceDetails.sTitle} />
//             </Helmet>
//             <Navbar3 hclass={'wpo-header-style-5'} />
//             <PageTitle pageTitle={serviceDetails.sBreadCrumb} pagesub={'Service'} />
//             <div className="wpo-service-single-area section-padding">
//                 <div className="container">
//                     <div className="row">
//                         <div className=" col-12">
//                             <div className="wpo-service-single-wrap">
//                                 <div className="wpo-service-single-item">
//                                     <div className='row'>
//                                     <div className="col-lg-8 wpo-service-single-main-img">
//                                         <img
//                                             src={serviceDetails.sImgS}
//                                             alt=""
//                                         />
//                                     </div>
//                                     <ServiceSidebar />
//                                     </div>
//                                 </div>

//                                 <div className="wpo-service-single-item">
//                                     <div className="wpo-service-single-title">
//                                         {serviceDetails.body}
//                                     </div>
//                                 </div>

//                                 <div className="wpo-service-single-item">
//                                     <div className="wpo-service-single-title">
//                                         <h3>Frequently Asked Questions</h3>
//                                     </div>
                                   
//                                     {serviceDetails.sTitle === 'Website Designing' ? (
//                                     <WebdesignFaq serviceTitle={serviceDetails.sTitle} />
//                                     ) : serviceDetails.sTitle === 'Website Development' ? (
//                                     <WebdevelopmentFaq serviceTitle={serviceDetails.sTitle} />
//                                     ): serviceDetails.sTitle === 'Social Media Marketing' ? (
//                                         <SocialMediaMarketingFaq serviceTitle={serviceDetails.sTitle} />
//                                     ): serviceDetails.sTitle === 'Software Development' ? (
//                                         <SoftwareDevelopmentFaq serviceTitle={serviceDetails.sTitle} />
//                                     ): serviceDetails.sTitle === 'Digital Marketing' ? (
//                                         <DigitalMarketingFaq serviceTitle={serviceDetails.sTitle} />
//                                     ): (
//                                     <SeoFaq serviceTitle={serviceDetails.sTitle} />
//                                     )}

//                                 </div>
                                

//                                 <div className="wpo-service-single-item">
//                                     <div className="wpo-service-single-title">
//                                         <h3>Related Service</h3>
//                                     </div>
//                                     <div className="wpo-service-area">
//                                         <div className="row align-items-center">
//                                             {Services.slice(0, 3).map(
//                                                 (service, srv) => (
//                                                     <div
//                                                         className="col-lg-4 col-md-6 col-12"
//                                                         key={srv}
//                                                     >
//                                                         <div className="wpo-service-item">
//                                                             <i
//                                                                 className={`fi ${service.icon}`}
//                                                             ></i>
//                                                             <Link
//                                                                 to={`/service/${service.slug}/${service.Id}`}
//                                                                 onClick={
//                                                                     ClickHandler
//                                                                 }
//                                                             >
//                                                                 <h2>
//                                                                     {
//                                                                         service.sTitle
//                                                                     }
//                                                                 </h2>
//                                                             </Link>
//                                                             <p>
//                                                                 {service.des2}
//                                                             </p>
//                                                         </div>
//                                                     </div>
//                                                 )
//                                             )}
//                                         </div>
//                                     </div>
//                                 </div>
                                                                   
//                                 {/* <div className="wpo-service-single-item">
//                                     <div className="wpo-service-contact-area">
//                                         <div className="wpo-contact-title">
//                                             <h2>
//                                                 Have project in mind? Let's
//                                                 discuss
//                                             </h2>
//                                             <p>
//                                                 Get in touch with us to see how
//                                                 we can help you with your
//                                                 project
//                                             </p>
//                                         </div>
//                                         <div className="wpo-contact-form-area">
//                                             <Contact />
//                                         </div>
//                                     </div>
//                                 </div> */}
//                                 {/* new comment start */}
//                             {/* </div>
//                         </div> */}
//                          {/* new comment end */}
//                         {/* <ServiceSidebar /> */}
//                          {/* new comment start */}
//                     {/* </div>
//                 </div> */}
                
//             {/* </div> */}
//             {/* new comment end */}
//             <Footer2 />
//             <Scrollbar />
//         </Fragment>
//     );
// };
// export default ServiceSinglePage;

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Navbar3 from '../../components/Navbar3/Navbar3';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import { Link, useParams } from 'react-router-dom';
import Services from '../../api/service';
import ServiceSidebar from './sidebar';
import Contact from './contact';
import Footer2 from '../../components/footer2/Footer2';
import WebdesignFaq from './WebdesignFaq';
import WebdevelopmentFaq from './WebdevelopmentFaq';
import SocialMediaMarketingFaq from './SocialMediaMarketingFaq';
import SoftwareDevelopmentFaq from './SoftwareDevelopmentFaq';
import SeoFaq from './SeoFaq';
import DigitalMarketingFaq from './DigitalMarketingFaq';
import ITOutsourcingFaq from './ITOutsourcingFaq';
import MobileAppFaq from './MobileAppFaq';
import ITConsultationFaq from './ITConsultationFaq';
import SystemMonitoringFaq from './SystemMonitoringFaq';





const ServiceSinglePage = (props) => {
  const { id } = useParams();
  const serviceDetails = Services.find((item) => item.Id === id);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <Fragment>
      <Helmet>
        <title>{`${serviceDetails.sTitle} | Matrix Mantra`}</title>
        <meta name="description" content={serviceDetails.sTitle} />
      </Helmet>
      <Navbar3 hclass={'wpo-header-style-5'} />
      <PageTitle pageTitle={serviceDetails.sBreadCrumb} pagesub={'Service'} />
      <div className="wpo-service-single-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="wpo-service-single-wrap">
                <div className="wpo-service-single-item">
                  <div className='row'>
                    <div className="col-lg-8 ">
                      <div className="wpo-service-single-main-img">
                        <img src={serviceDetails.sImgS} alt="" loading='lazy'/>
                      </div>
                      <div className="wpo-service-single-title">
                        {serviceDetails.body}
                      </div>
                      
                      
                    </div>
                     <ServiceSidebar blLeft={props.blLeft} />
                     <div className="wpo-service-single-item">
                        <div className="wpo-service-single-title">
                          <h3 style={{ marginTop: '100px' }}>Frequently Asked Questions</h3>
                        </div>
                        {serviceDetails.sTitle === 'Website Designing' ? (
                          <WebdesignFaq serviceTitle={serviceDetails.sTitle} />
                        ) : serviceDetails.sTitle === 'Website Development' ? (
                          <WebdevelopmentFaq serviceTitle={serviceDetails.sTitle} />
                        ) : serviceDetails.sTitle === 'Social Media Marketing' ? (
                          <SocialMediaMarketingFaq serviceTitle={serviceDetails.sTitle} />
                        ) : serviceDetails.sTitle === 'Software Development' ? (
                          <SoftwareDevelopmentFaq serviceTitle={serviceDetails.sTitle} />
                        ) : serviceDetails.sTitle === 'Digital Marketing' ? (
                          <DigitalMarketingFaq serviceTitle={serviceDetails.sTitle} />
                        ) : serviceDetails.sTitle === 'IT Outsourcing' ? ( 
                          <ITOutsourcingFaq serviceTitle={serviceDetails.sTitle} />
                        ) : serviceDetails.sTitle === 'Mobile App Development' ? ( 
                          <MobileAppFaq serviceTitle={serviceDetails.sTitle} />
                        ) : serviceDetails.sTitle === 'IT Services Consulting' ? ( 
                          <ITConsultationFaq serviceTitle={serviceDetails.sTitle} />
                        ): serviceDetails.sTitle === 'System Monitoring and Visualization' ? ( 
                          <SystemMonitoringFaq serviceTitle={serviceDetails.sTitle} />
                        ):
                        (
                          <SeoFaq serviceTitle={serviceDetails.sTitle} />
                        )}
                      </div>
                    
                    

                    
 


                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
      <Scrollbar />
    </Fragment>
  );
};

export default ServiceSinglePage;
