// import React from 'react';
// import blogs from '../../api/blogs';
// import { Link } from 'react-router-dom';
// import bImg from '../../images/blog/shape.png';
// import bImg2 from '../../images/blog/shape2.png';
// import SectionTitle from '../SectionTitle/SectionTitle';

// const ClickHandler = () => {
//     window.scrollTo(10, 0);
// };

// const BlogSection = ({ landingPage }) => {

    

//     // Filter blogs based on the landingPage prop
    
//     let displayedBlogs;

//     if (landingPage === 'home') {
//         // Sort blogs by date in descending order to get the latest ones first
//         const sortedBlogs = blogs.sort((a, b) => new Date(b.create_at) - new Date(a.create_at));
//         // Take the first three blogs
//         displayedBlogs = sortedBlogs.slice(0, 3);
//     } else {
//         // Filter blogs based on the landingPage prop
//         displayedBlogs = blogs.filter(blog => blog.category === landingPage);
//         const sortedBlogs = displayedBlogs.sort((a, b) => new Date(b.create_at) - new Date(a.create_at));
//         displayedBlogs = sortedBlogs.slice(0, 3);
//     }

//     return (
//         <div className="wpo-blog-area-s2 section-padding section-bg">
//             <div className="container">
//                 <SectionTitle
//                     subTitle={'Blog Post'}
//                     Title={'Latest News From Blog'}
//                 />
//                 <div className="wpo-blog-wrap">
//                     <div className="row align-items-center">
//                         {/* {filteredBlogs.map((blog, bl) => ( */}
//                         {displayedBlogs.map((blog, bl) => (
//                             <div className="col-lg-4 col-md-6 col-12" key={bl}>
//                                 <div className="blog-item">
//                                     <div className="blog-img" style={{aspectRatio:8/7, backgroundPosition:"top"}}>
//                                         <img src={blog.screens} alt=""/>
//                                     </div>
//                                     <div className="blog-content">
//                                         <ul>
//                                             <li>
//                                                 <a href={`/blog/${blog.slug}/${blog.id}`}>
//                                                     {blog.create_at}
//                                                 </a>
//                                             </li>
//                                             <li>{blog.author}</li>
//                                         </ul>
//                                         <h2>
//                                             <Link
//                                                 onClick={ClickHandler}
//                                                 // to={`/blog/${blog.slug}/${blog.id}`}
//                                                 to={`/blog/${blog.slug}`}
//                                             >
//                                                 {blog.title}
//                                             </Link>
//                                         </h2>
//                                         <Link
//                                             className="arrow-btn"
//                                             onClick={ClickHandler}
//                                             // to={`/blog/${blog.slug}/${blog.id}`}
//                                             to={`/blog/${blog.slug}`}
//                                         >
//                                             <i className="fi flaticon-right-arrow-1"></i>
//                                         </Link>
//                                     </div>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 </div>
//             </div>
//             <div className="shape-1">
//                 <img src={bImg} alt="" />
//             </div>
//             <div className="shape-2">
//                 <img src={bImg2} alt="" />
//             </div>
//         </div>
//     );
// };

// export default BlogSection;

import React from 'react';
import blogs from '../../api/blogs';
import { Link } from 'react-router-dom';
import bImg from '../../images/blog/shape.png';
import bImg2 from '../../images/blog/shape2.png';
import SectionTitle from '../SectionTitle/SectionTitle';
import './BlogSection.css'; // Import custom CSS

const ClickHandler = () => {
    window.scrollTo(10, 0);
};

const BlogSection = ({ landingPage }) => {
    // Filter blogs based on the landingPage prop
    let displayedBlogs;

    if (landingPage === 'home') {
        // Sort blogs by date in descending order to get the latest ones first
        const sortedBlogs = blogs.sort((a, b) => new Date(b.create_at) - new Date(a.create_at));
        // Take the first three blogs
        displayedBlogs = sortedBlogs.slice(0, 3);
    } else {
        // Filter blogs based on the landingPage prop
        displayedBlogs = blogs.filter(blog => blog.category === landingPage);
        const sortedBlogs = displayedBlogs.sort((a, b) => new Date(b.create_at) - new Date(a.create_at));
        displayedBlogs = sortedBlogs.slice(0, 3);
    }

    return (
        <div className="wpo-blog-area-s2 section-padding section-bg">
            <div className="container">
                <SectionTitle
                    subTitle={'Blog Post'}
                    Title={'Latest News From Blog'}
                />
                <div className="wpo-blog-wrap">
                    <div className="row align-items-center">
                        {displayedBlogs.map((blog, bl) => (
                            <div className="col-lg-4 col-md-6 col-12" key={bl}>
                                <div className="blog-item">
                                    <div className="blog-img" style={{ aspectRatio: 8 / 7, backgroundPosition: "top" }}>
                                        <img src={blog.screens} alt="" />
                                    </div>
                                    <div className="blog-content">
                                        <ul>
                                            <li>
                                                <a href={`/blog/${blog.slug}/${blog.id}`}>
                                                    {blog.create_at}
                                                </a>
                                            </li>
                                            <li>{blog.author}</li>
                                        </ul>
                                        <h2>
                                            <Link
                                                onClick={ClickHandler}
                                                to={`/blog/${blog.slug}`}
                                            >
                                                {blog.title}
                                            </Link>
                                        </h2>
                                        <Link
                                            className="arrow-btn"
                                            onClick={ClickHandler}
                                            to={`/blog/${blog.slug}`}
                                        >
                                            <i className="fi flaticon-right-arrow-1"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="shape-1">
                <img src={bImg} alt="" />
            </div>
            <div className="shape-2">
                <img src={bImg2} alt="" />
            </div>
        </div>
    );
};

export default BlogSection;

