import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Projects from '../../api/project';
import { Link } from 'react-router-dom';
import SectionTitle2 from '../SectionTitle2/SectionTitle2';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Tabs, Tab } from '@material-ui/core';
import './ProjectSection.css'; // Import custom CSS

const settings = {
    dots: false,
    rows: 2,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const ClickHandler = () => {
    window.scrollTo(12, 0);
};

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

const ProjectSection = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <section
            className={`wpo-project-section section-padding ${props.pClass}`}
        >
            <div className="container">
                <SectionTitle2
                    subTitle={'OUR PORTFOLIO'}
                    Title={'Successful work around the Globe'}
                />
            </div>
            {/* <Paper className={classes.root}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Item One" />
                    <Tab label="Item Two" />
                    <Tab label="Item Three" />
                </Tabs>
            </Paper> */}

            <div className="container-fluid">
                <div className="row">
                    <div className="col col-xs-12 sortable-project">
                        <div className="wpo-project-container project-slider owl-carousel">
                            <Slider {...settings}>
                                {Projects.map((project, prj) => (
                                    <div className="grid" key={prj}>
                                        <a
                                            href={`/project/${project.slug}/${project.Id}`}
                                        >
                                            <div className="wpo-project-item">
                                                <img
                                                    src={project.pImg}
                                                    alt="project"
                                                    className="img img-responsive"
                                                    loading='lazy'
                                                />

                                                <div className="wpo-project-text">
                                                    {' '}
                                                    <h3>{project.title}</h3>
                                                    <span>
                                                        {project.subTitle}
                                                    </span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div>
                <div className="mycin">
                    {Projects.slice(0, 9).map((project, prj) => (
                        <div style={{ textAlign: '-webkit-center' }} key={prj}>
                            <div>
                                <img
                                    style={{
                                        width: '500px',
                                        paddingRight: '50px',
                                        paddingLeft: '50px',
                                    }}
                                    src={project.pImg}
                                    alt="project"
                                />
                                <div className="myTest">
                                    <h3>
                                        <a href={project.purl} target="_blank">
                                            {project.title}
                                        </a>
                                    </h3>
                                    <span>{project.subTitle}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div> */}
        </section>
    );
};

export default ProjectSection;